@import url('https://fonts.googleapis.com/css?family=Raleway');
body {
  min-height: 100vh !important;
}
#root {
  margin-bottom: 0px !important;
}
#login .new-footer {
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;

}

#login label {
  margin-top: 2rem;
  color: #000;
}

hr {
  width: 100%;
  border-color: #ccc;
}

.center{
    font-family: 'Raleway', sans-serif;
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo {
    margin-bottom: 40px;
}

.card{
    background-color: #fff;
    border-radius: 15px;
    padding: 70px 50px;
    width: 500px;
}

.card > form{
    display: flex;
    flex-direction: column;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item{
    width: 100%;
    height: 42px;
    padding: 6px 10px;
    text-align: left;
    font-size: 13px;
    line-height: 1.5;
    color: #595959;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}

#login .form-submit{
    width: 100%;
    background: #1d89cf !important;
    background: linear-gradient(to bottom,#1e8cd3 0,#1a7ab9 100%) !important;
    background-repeat: repeat-x;
    cursor: pointer;
}
.form-submit:hover{
   opacity: 0.6;
}
.link-forgot {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.uf {
  text-transform:uppercase;
}
