/*Layout Global*/

h5{
  margin-top: 25px;
  font-weight: bold;
  font-size: 16px;
}
table {
  margin-bottom: 40px;
}

p {
  margin-bottom: 2px;
}

label {
  margin-bottom: 2px;
}

.ant-modal label {

  display: block;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(95deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%) !important;
  border-radius: 10px;
}

#root {
  margin-top: 0px;
  margin-bottom: 70px;
}
#header{
  background-color: rgba(0,0,0,1);
  height: 100px;
}

#header img {
  margin-top: 20px;
  margin-left: 10%;
  max-width: 80%;
}

.sheet {
  margin-top: 60px;
}

.red {
  color: red;
}

.yellow {
  color: #ffd800;
}

.alert {
  border: 0px solid transparent;
}

.left {
  float: left;
}

.right {
  float: right;
}

.table-responsive {
  overflow-x: initial;
}

.afiliados-text-center {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover-box{
  max-width: 276px;
  padding: 10px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  /*-webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.2);*/
  line-break: auto;
}

.popover-box {
  background: #ddd;
  border: 2px solid #94bcd8;
  color: #000;
}

.react-tiny-popover-container {
  z-index: 1201;
}

/*.react-tiny-popover-container div div:first-child{
  border-top: 10px solid #94bcd8 !important;
}*/

.treeview-menu {
  padding-left: 25px;
}

.wrapper #footer #tiideal-logo {
  margin-left: 230px;
  font-size: 14px;
  float: left;
  width: 41%;
}
.wrapper #suporte-rodape {
    float: right;
    font-size: 14px;
    margin-top: 14px;
    width: 42%;
}

.home .content-wrapper-inner{
  min-height: 30vh;
}

.generate-document .content-wrapper-inner, .cashflow .content-wrapper-inner, .edit-template .content-wrapper-inner {
  display: block;
  padding-bottom: 20px;
}

.sidebar .menu-open {
  visibility: visible;
  max-height: 300px;
  transition: max-height 400ms;
}

.sidebar-menu {
  height: 90%;
  overflow-y: scroll !important;
  scrollbar-width: thin;
}

.sidebar-menu span.nav-text {
  top: -5px;
  position: relative;
}

.sidebar-menu > li > a > .fa-tachometer, .sidebar-menu > li > a > .fa-balance-scale  {
  width: 24px;
  top: -5px;
  position: relative;
}
.fa-dashboard:before, .fa-tachometer:before {
  font-size: 24px;
}

.home-menu {
  padding: 0px;
}

.home-menu li {
  color: #222;
}

.home-menu .treeview-menu {
  max-height: inherit;
  visibility: visible;
  display: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.home-menu .treeview-menu > li {
  border-bottom: 1px solid #ddd;
}

.btn{
  font-weight: 700;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    line-height: 1.5;
    padding: 10px 18px;
    font-size: 16px;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    border-color: #e9e9e9;
    -webkit-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -moz-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -ms-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -o-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -webkit-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}

.btn-small {
  padding: 7px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary.focus, .btn-primary:focus{
  color: #fff;
  background-color: #389028;
  border-color: #1f5d18;
}


#footer{
  background-color: #f8f8f8;
  border-top: 1px solid #CCC;
  height: 80px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: none;
}

#footer img {
    position: absolute;
    margin-left: 5%;
    height: 70px;
    font: 11px Tahoma;
    color: #FFF;
    background-size: 70px;
    float: left;
}

#footer span {
    line-height: 30px;
    float:right;
    margin-top: 10px;
    margin-right: 20%;
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border-radius: 4px 4px 0 0;
}

thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px;
}

thead > tr > th {
    background: #f3f2f2;
}

thead > tr > th {
    padding: 10px 16px;
}

tbody > tr > td {
    padding: 5px 8px 5px 8px;
    word-break: break-word;
    vertical-align: top;
}

thead > tr > th {
    background: #f3f2f2;
    transition: background 0.3s ease;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
}

tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    transition: all 0.3s, border 0s;
}

.panel-heading strong{
  width: 200px;
  display: inline-block;
}

.push-menu {
  transform: rotate(-180deg);
}
.sidebar-collapse .push-menu {
  transform: rotate(0deg);
}
.skin-black .main-header {
  position: fixed;
  width: 100%;
  top: 0;
}
.skin-black .main-header .logo {
  position: fixed;
  height: 60px;
}
.skin-black .main-sidebar, .skin-black .left-side {
  position: fixed;
}
.actions-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.skin-black .main-sidebar, .skin-black .left-side {
      background-color: #337ab7;
      background: linear-gradient(95deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%);
}
.skin-black .sidebar a {
    color: #fff;
}
.skin-black .sidebar a:hover, .skin-black .sidebar li:hover, .skin-black .sidebar-menu>li:hover>a, .skin-black .sidebar-menu>li.active>a, .skin-black .sidebar-menu>li.menu-open>a {
      background: rgba(44, 149, 189, 0.32);
}

.skin-black .sidebar-menu>li>.treeview-menu {
    background: rgba(44, 149, 189, 0.42);
}
.skin-black .sidebar-menu .treeview-menu>li>a {
    color: #fff;
}

.skin-black .sidebar-menu .treeview-menu>li.active>a, .skin-black .sidebar-menu .treeview-menu>li>a:hover {
    background: rgba(000, 220, 255, 0.72);
}

.treeview-menu {
  position: relative;
}

.treeview-menu > li.active {
  background: rgba(44, 149, 255, 0.12);
}

.skin-black .sidebar-form input[type="text"], .skin-black .sidebar-form .btn {
    background-color: #fff;
}

.well{ margin: 10px; margin-top: 70px; font-size: 16px;}

.form-group-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form-group-outer .form-group {
  width: 32%;
}

.form-group-outer .form-group+.form-group {
  margin-left: 2%;
}

/* Login, Cadastro e Recuperar senha */

.isoSignUpPage, .isoSignInPage, .isoForgotPassPage, .isoResetPassPage {
    min-height: auto;
    height: auto;
    max-height: 70%;
}

.isoInputWrapper {
  margin-top: 30px;
}

.isoLeftRightComponent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.card, .isoSignUpPage .isoSignUpContent, .isoSignInPage .isoLoginContent, .isoForgotPassPage .isoFormContent, .isoResetPassPage .isoFormContent {
  border: 2px solid #eee;
  margin: 30px;
  border-radius: 10px;
  min-height: 30px;
  padding-bottom: 30px;
}

.isoSignUpPage .isoSignUpContentWrapper, .isoSignInPage .isoLoginContentWrapper, .isoForgotPassPage .isoFormContentWrapper, .isoResetPassPage .isoFormContentWrapper{
  height: auto;
}

/* Listagem de Processos*/

.form-pesquisa-tribunal {
  width: 100%;
}

.container-input-pesquisa, #opt_pesquisa, .container-search {
  margin-bottom: 5px;
}

#divclientexoponente {
  text-transform: uppercase;
}
.reportEventList center {
  font-size: 16px;
  font-weight: bold;
}
.reportEventList .user {
  /*width: 15%;*/
}
.actions .pull-right {
  right: initial;
  left: 30px;
  top: 10px;
  position: relative;
}
.ged-download-button {
  right: 10px;
  top: 10px;
  position: relative;
}
.movement_actions {
  width: 100px;
  min-width: 100px;
}
.movement_actions .pull-right{
  position: relative;
}
.action {
  vertical-align: top;
}
#table-partes .ant-divider, .actions .ant-divider {
  left: 55px;
  position: absolute;
  top: 5px;
}
table .fa-trash {
  top: 10px;
  width: 30px;
  height: 30px;
  position: relative;
}
.list-lawsuit .btn{
  font-size: 13px;
  font-weight: 500;
  width: 135px;
  height: 44px;
}
.manual-list-lawsuit .btn {
  width: 180px;
}
.add-lawsuit #cancelButton {
  float: right;
  margin-top: 10px;
  margin-left: 10px;
}
.list-lawsuit .btn-warning {
    padding: 2px 3px;
    font-size: 12px;
}

.list-lawsuit .btn label{
  font-weight: 500;
  white-space: pre-wrap;
}

.list-lawsuit .box-body {
  padding-left: 10px;
  padding-right: 10px;
}

.list-lawsuit .row {
  margin: 0;
}

/*.form-pesquisa-tribunal .row div {
  margin-bottom: 5px;
}*/

.table_lawsuit_number small {
  display: block;
}

.container-label-pesquisa {
  display: inline-block;
  height: 44px;
}
.form-pesquisa-tribunal label, .form-pesquisa-tribunal #inp_pesquisa {
  height: 41px;
}
.form-pesquisa-tribunal label {
  margin-top: 10px;
}
.form-pesquisa-tribunal .opt_pesquisa {
  width: 98%;
  display: inline-block;
}
.form-pesquisa-tribunal .opt_pesquisa div {
  min-height: 25px;
}
.form-search {
  width: 360px;
}
.container-search {
  display: inline-block;
}
.container-search .btn {
  margin-left: 0px; margin-right: 0px;
  padding-left: 14px; padding-right: 14px;
}
/*.container-search .btn:first-child {
  margin-right: 55px;
}*/
.horizontal-space {
  margin-left: 10px !important;
}
.vertical-space {
  margin-top: 20px !important;
}
.ant-table .fa, table .fa {
    font-size: 18px;
    cursor: pointer;
}
.ant-table .fa:hover, table .fa:hover  {
  color: #1890ff;
}
.ant-divider, .ant-divider-vertical {
    margin: 8px;
    height: 16px;
}
.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
  clear: both;
}
.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}

.show-hide-button-container {
  margin: 20px auto;
  display: table;
}

.show-table {
  display: table-row !important;
}

.highlight-table .fa-star, .fa-star {
  color: #ffd800 !important;
}

.alert-pub {
  font-size: 18px;
  color: #f10;
}

/*Detalhes do Processo*/

.menu-topo-processo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-print, .add-incoming {
  min-width: 300px;
  margin-bottom: 5px;
}


#bt-edit-dados-topo-processo, #bt-edit-dados-complementares-processo {
  right: -15px;
  color: #1890ff;
}

.panel {
  margin-bottom: 5px;
}

.panel-primary>.panel-heading {
      border-color: transparent;
      background: linear-gradient(135deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%);
      padding: 9px 15px;
}

.details-lawsuit .box-body .panel-primary .panel-heading {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}

.details-lawsuit .box-body .panel-primary .panel-heading a{
  color: white;
}
.details-lawsuit .box-body .panel-primary .panel-heading a:hover {
  color: #40a9ff;
}
.menu-topo-processo .btn {
  padding: 10px 10px;
  font-size: 14px;
}

.panel-default {
  margin-top: 10px;
}

.panel-heading {
  padding: 6px 15px;
}

.panel-body {
  padding: 5px;
}

.tribunal .panel-body {
  display: flex;
  flex-direction: column;
}

.tribunal .collapse {
  display: none;
}

.tribunal .collapse.in {
  display: block;
}

.tribunal .panel-body .btn-primary {
  margin: 0 auto;
}

.text-success {
  color: #79b131;
}

.pointer {
  cursor: pointer;
}

.panel-heading > .pointer:after {
  content: "\f078"; /* fa-chevron-down */
  font-family: 'FontAwesome';
  position: absolute;
  right: 0;
}
.panel-heading > .pointer[aria-expanded="true"]:after {
  content: "\f077"; /* fa-chevron-up */
}
.panel-title {
  display: block;
  position: relative;
}

small.form-text{padding-left: 10px;}

.ant-modal .btn-danger{
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

#bt-update-valor-causa, #bnt-incluir-parte, #btn-switch-parte, #bnt-cadastrar-parte-cadastrada, #div-parte-cadastrada .btn-danger, .cep-btn, #bnt-cadastrar-info, #bnt-cadastrar-info_moviment, #btn-cadastrar-evento {
  padding: 6px 20px;
  font-size: 13px;
}
#panel-edit-dados-topo-processo .btn-danger, #panel-edit-dados-topo-processo .btn-primary, .modal-dialog .btn, .modalSettings .btn, #bnt-cadastrar-ged, .add-lancamento{
  padding: 6px 20px;
  font-size: 13px;
}
#bnt-incluir-parte, #bnt-cadastrar-info, #bnt-cadastrar-info_moviment, #btn-cadastrar-evento, #bnt-cadastrar-ged, .add-lancamento {
  margin-bottom: 10px;
}

.box-success .form-submit {
    float: right;
}

.btn-group .clientexo, .btn-group .cxoponente {
  padding: 2px 10px;
  font-weight: 500;
  font-size: 13px;
}

.client, .uppercase {
  text-transform: uppercase;
}

.type_personage{
  width: 20%;
}
.personage_name{
  width: 40%;
}
.pull-right {
  position: absolute;
  right: 0;
}
.movement_actions .pull-right .fa-pencil-square-o{
  width: 30px;
}

.pull-right .fa-pencil-square-o{
  font-size: 24px;
  width: 50px;
  align-self: center;
}
.edit-dados-processo-label, .edit-label{
  width: 65px;
  display: block;
  align-self: center;
}
#bt-edit-dados-processo, #bt-edit-dados-complementares-processo, #bt-edit-dados-topo-processo, .bt-edit{
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.details-lawsuit .row, .add-lawsuit .row {
  min-height: 40px;
  margin-top: 10px;
}
.container-complementar-processo .row {
  min-height: 20px;
}
.fa-phone {
  margin-left: 10px;
}
.cadastro-partes-cadastrada {
  border: 1px #ddd solid;
  border-radius: 7px;
  width: 80%;
  margin: 0px auto;
  padding: 0px;
  display: table;
}
#form-group-rg {clear: both;}
.form-group {
    margin-bottom: 5px;
}
.form-group-cep {    width: 16%;float: left;}
.form-horizontal .form-group {
  margin-left: 0; margin-right: 0; min-height: 76px;
}
.form-horizontal  .action-buttons {
  min-height: 30px;
}
.wrap-form .row {
  margin-left: 0; margin-right: 0;
}
.group-buttons-actions{
  margin-top: 10px; margin-bottom: 10px;
}
.input-lg {
  height: 38px;
}
.cep-btn {
  margin-top: 30px;
  padding: 6px 13px;
}

.header-personage {
    display: table;
    margin: 0px auto;
    min-height: 70px;
    background: linear-gradient(135deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%);
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 10px;
}
.header-personage h4 {
  margin-top: 20px;
  color: white;
  font-weight: 700;
}

/* Partes */
.cliente_ou_oponente{
  width: 32%;
}
.parte-item, .movement-item, .event-item {
    height: 55px;
}
table .pull-right .fa-pencil-square-o {
    font-size: 16px;
}
table .edit-label {
    font-size: 12px;
}

/*Andamentos e comentários*/
#movement-table .andamentos, #publications-table .publication span, .container-publicacoes .publication span, #info-table .informacoes span, #schedule-table .description span {
  font-size: 14px;
  font-weight: 500;
  width: 282px;
  display: block;
  white-space: break-spaces;
}
.container-publicacoes .pub-info span {
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  display: block;
  white-space: break-spaces;
}
.comment_item:nth-child(+n+4) {
    display: none;
}
.parte-item:nth-child(+n+4), .movement-item:nth-child(+n+11), .info-item:nth-child(+n+4), .event-item:nth-child(+n+11){
    display: none;
}
.document-item:nth-child(+n+11){
    display: none;
}
.comment-button-container {
  display: table; margin: 0 auto;
}
.andamentos+.fa-comment{
  margin-left: 10px;
}
.fa-comment{
  float: left;
}
.comments-list {
  max-width: 100%;
  padding: 0;
  margin-top: 10px;
}
li {
  list-style: none;
}
.date_comment {
  display: block;
  font-size: 12px;
  margin-left: 2%;
}
.scrap-box{
  background: rgba(15,207,255,0.3);
  width: 91%;
    display: inline-block;
    color: #444;
    min-height: 40px;
    margin: 10px;
    margin-top: 0px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400;
}
.reverse .scrap-box {
  background: rgba(15,207,255,0.2);
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.comments-list .fa-times {
  margin-left: 10px;
}
#hide-comment-button, .hide-button, .hide_manual {
  display: none;
}

.panel-default>.panel-heading {
  background-color: #f1f1f1;
}
.panel-body {
  position: relative;
}
.ant-table-thead > tr > th {
    background: #fbfbfb;
}
span.action {
  position: relative;
}
table .number_movement, table .number {
  width: 5%;
  vertical-align: top;
  padding-left: 16px;
  /*padding-top: 15px;*/
}
table .date {
  width: 15%;
  min-width: 100px;
  vertical-align: top;
}
table .table_client_date {
  width: 17%;
  vertical-align: top;
  padding-left: 15px;
}
table .table_endereco {
  width: 55%;
  vertical-align: top;
}
table .movement,  table .informacoes{
  width: 70%;
}
table .publication{
  width: 55%;
}
table .description, table .title, table .informacoes, table .movement, .data-lawsuit-assunto, table .publication{
  white-space: pre-wrap;
  overflow-wrap: break-word;
  text-align: justify;
}
#schedule-table .date {
  width: 11%;
}
table .title, table .category {
  width: 15%;
  min-width: 100px;
}
table .description{
  width: 30%;
}


/*Home*/
.home .content-wrapper {
    margin-top: -40px;
}
.welcome {
  display: none;
}

.home-menu .pull-right {
  right: 15px;
  top: 12px;
}
.panel-primary h4 {
  color: #fff;
  font-weight: bold;
}

.home-menu .treeview{
  background-color: #f1f1f1;
  border: 1px solid;
  border-color: #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 6px 15px;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  cursor: pointer;
  font-size: 14px;
  position: relative;
  margin: 5px;
}
.home-menu .treeview label, .home-menu span {
  cursor: pointer;
}
.home-menu li a {
  width: 100%;
  display: block;
  color: #222;
}
.home-menu li ul {
  background-color: #fff;
  padding: 10px;
  margin: 10px;
}
.home-menu span.nav-text {
    padding-left: 0px;
}
.last-angle {
  color: white;
  position: absolute;
  top: 10px;
  right: 20px;
}
.active > a > .last-angle {
  transform: rotate(-270deg);
}
.home-menu li > a > .fa-angle-left, .last-angle,
.home-menu li > a > .pull-right-container > .fa-angle-left {

  -webkit-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}
.home-menu li > a> .fa-angle-left, .last-angle {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.home-menu .menu-open > a > .fa-angle-left, .menu-open > a > .last-angle,
.home-menu .active > a >  .fa-angle-left,
.home-menu .menu-open > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-270deg);
  -ms-transform: rotate(-270deg);
  -o-transform: rotate(-270deg);
  transform: rotate(-270deg);
}
.help_icon {
  /*width: 24px;*/
  width: 16px;
  height: 14px;
  margin-left: 05px;
  margin-right: 05px;
  margin-top: -4px;
}
.lawsuit_search .help_icon {
  margin: 05px;
  margin-top: 23px;
}
.input-container-search, .btn-container-search {
  float: left;
}
.btn-container-search {
  margin-top: 10px;
  width: 10%;
}
#top-chart-container .help_icon, .header-personage .help_icon, .header-h4 .help_icon {
  position: absolute;
  margin-top: 0px;
}
#div-parte-cadastrada .help_icon {
  position: absolute;
  margin-top: 8px;
}
.starred {color: orange; width: 24px;}
.star_container {
  margin-left: 10px;
  position: relative;

}
.number_starred{
  font-size: 12px;
  color: black;
  font-weight: bold;
  position: relative;
  top: -7px;
}
.ant-radio-button-wrapper-checked {
  background-image: none;
  box-shadow: none;
  background-color: #4482FF;
  border-color: #4482FF;
  color: #ffffff;
}

/*Info Lawsuit - Registros de Ações Internas */
.date-info-lawsuit {
  display: block;
  width: 100%;
  text-align: center;
}

/* FlashMessage */
.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear;
  height: 0px;
  margin: 0;
  padding: 0;
}

.flash-message {
  width: 50%;
  position: fixed;
  bottom: 75px;
  right: 57px;
  z-index: 5000;
}

.alert-success {
  background-color: #dff0d8 !important;
  border-color: #d6e9c6 !important;
  color: #3c763d !important;
}
.alert-success a {
  color: #1890ff !important;
}
/*User*/
.navbar-custom-menu .user .user-header small {
    color: rgba(255, 255, 255, 0.8);
}
.navbar-custom-menu .user .user-header img {
  display: inline-block;
}

.details-lawsuit-bt-print {
  height: 100%;
}

#details-lawsuit .details-lawsuit-bt-print{
  min-width: 100px;
}

/*DataLawsuit*/
.button-group{
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}
.error {
  color: red;
}

.tribunal-modal-button{
  padding: 7px;
  font-size: 12px;
}

.container-topo-processo .modal .form-control {
     width: 100%;
     margin-left: 0px;
}

/*AdditionalDataLawsuit*/
.delete-select-additional-lawsuit {
  position: absolute;
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.delete-select-additional-lawsuit .fa-pencil-square-o {
  align-self: center;
  font-size: 20px;
  width: 50px;
}
#panel-edit-dados-complementares-processo .input-lg {
  font-size: 14px;
}
#panel-edit-dados-complementares-processo .settings, .manager-template .settings {
  font-size: 20px;
  margin-top: 7px;
  margin-left: -5px;
}
#panel-edit-dados-complementares-processo .form-group {
  padding-left: 0px;
  padding-right: 0px;
}
#pasta {
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin-left: 15px;
  width: 237px;
}
input.status {
  margin-left: 15px;
  width: 237px;
}
.pasta-label, .fase-label, .fase,  {padding-left: 0px;}
.modal .form-control, .modalSettings .form-control {
  width: 65%;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
}
.modal label, .modalSettings label {
  margin-top: 5px;
}
.modal option, .modalSettings option {
  padding: 5px;
}
.info {
  color: green;
  clear: both;
  margin-top: 10px;
  width: 300px;
  display: inline-block;
  margin-right: 20px;
  text-align: left;
}
.modal select.settings, .modalSettings select.settings {
    width: 70%;
    margin-left: 15px;
    margin-right: 15px;
    height: 190px;
    border-radius: 5px;
}
.modal .delete {
  position: absolute;
}
.modal .delete:hover  {
  color: red;
}
.modal-footer {
  margin-right: 16px;
}
.not-defined { color: #ddd;}

/*Related*/
#related-lawsuit-target table .fa-trash, .incoming-item .fa-trash {
  right: initial;
}

/* HEADERBAR */
/*.main-header .sidebar-toggle {
  width: 50px;
}*/
.nav:before, .navbar-collapse:before, .navbar-header:before, .navbar:before,
.nav:after, .navbar-collapse:after, .navbar-header:after, .navbar:after {
  display: none;
}
.main-header .sidebar-form{
  width: 255px;
}
.lawsuit_search, .blank {
  width: 10%;
  display: none;
}

.lawsuit_search label {margin-top: 20px;}

.main-header .sidebar-form .input-group {
  width: 100%;
  display: inline-block;
}

.main-header .sidebar-form input[type="text"] {
  padding: 0;
  padding-left: 10px;
}

.lawsuit_search input {
  border-radius: 3px !important;
}

.main-header #search-btn {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 34px;
}

.main-header .navbar-custom-menu, .main-header .navbar-right {
    display: flex;
}

.skin-black .main-header .navbar {
  display: flex;
  justify-content: space-between;
  max-height: 60px;
  min-height: 60px;
}


.navbar-nav > .user-menu > .dropdown-toggle {
    /*padding: 5px;
    margin-right: 30px;*/
    padding-top: 10px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    /*width: 132px;*/
}

/*Agenda Calendar*/
.rbc-month-view{
  border-radius: 5px;
}
.rbc-header {
    padding: 10px 3px;
}
.rbc-toolbar button {
    color: #788195;
    font-size: 14px;
    display: inline-block;
    margin: 0;
    text-align: center;
    height: 35px;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ebebeb;
    padding: 0 15px;
    outline: 0;
    line-height: normal;
    white-space: nowrap;
    cursor: pointer;
    text-transform: capitalize;
    -webkit-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -moz-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -ms-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -o-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    -webkit-transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
    transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: none;
    background-color: #4482FF;
    border-color: #4482FF;
    color: #ffffff;
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus, .rbc-toolbar button:hover {
    color: #ffffff;
    background-color: #4482FF;
    border-color: #4482FF;
}
.rbc-event {
    border-radius: 5px;
    padding: 3px 15px;
}
.rbc-today {
    background-color: #f7f8f9;
}
.rbc-current-time-indicator {
    position: absolute;
    z-index: 1;
    left: 0;
    height: 1px;
    background-color: #4482FF;
    pointer-events: none;
}
.rbc-current-time-indicator::before {
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    content: " ";
    background-color: #4482FF;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-transition: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}
.rbc-agenda-date-cell, .rbc-agenda-time-cell { width: 10%}

.add-event{ margin-left: 0px;}

.round-flag {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 3px;
}

#label-sponsor-event {
  display: flex;
  justify-content: space-between;
}

#label-copy-event {
  display: flex;
  justify-content:first baseline;
}

.fc-time {
  float: left;
  width: 37%;
  margin-top: 1px;
  font-size: 10px;
}
.fc-title {
  width: 63%;
  height: 16px;
  white-space: nowrap;
  font-family: 'Roboto';
  font-size: 11px;
  margin-top: 1px;
}
.rbc-time-content {
  overflow-y: visible !important;
}

.small-link-lawsuit {
  font-size: 12px;
  padding: 5px;
}

.ant-modal-body {display: table; width: 100%; padding: 10px;}

.ant-modal-content .btn-default{font-size: 14px; padding: 10px 12px;}

.ant-modal .category {width: 80%; float: left; margin-right: 15px}

.ant-modal .settings {float: left; margin-top: 5px;}

.ant-modal .form-group {
  min-height: 7px;
}

.ant-switch {margin-left: 10px;}

#typeEventContainer {justify-content: left; margin: 5px; padding: 0px;}
#typeEventContainer .control-label {text-align: center;}
#compromisso, #tarefa, #notificar {width: 246px;}
.formCalendar {width: 98% !important;}
#compromisso-container .form-control {min-height: 38px;}
.rbc-toolbar button:focus {
    background-color: initial;
    color: #788195;
    border: 1px solid #ebebeb;
}
#repeat-group { margin-left: 20px; margin-right: 20px; width: 64%;}
#finish_in_container { float: right; width: 23%; margin-top: -22px;}
.ant-modal-content .action-buttons-container {
  margin-bottom: 20px;
  float: right;
  /*margin-top: -50px;*/
}
.action-buttons-container button+button {
  margin-left: 10px;
}
.ant-modal-content .rbc-btn-group {
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
.rbc-toolbar button.rbc-active, .rbc-toolbar button:hover, .rbc-toolbar button:active {
    border-color: #979798;
}
.panel-primary {
    border-color: transparent;
}
.ant-modal-content .control-label{
  width: 100%;
}
.field-emails {
  width: 100%;
}
.fieldset {
  display: table;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
}
.fieldset .title-container {
  font-size: 15px;
  width: 100%;
  float: left;
  padding: 25px;
  font-weight: bold;
  background: linear-gradient(135deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%);
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}
.add-lawsuit .btn-primary {
  float: right;
  margin-top: 10px;
}
#panel-edit-dados-topo-processo .form-group label {
  width: 100%;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-btn-primary {
  background-color: #79b131;
  border-color: #79b131;
}

.rbc-calendar {
  /*height: auto !important;*/
  overflow-x: scroll;
}
.rbc-month-view {
  min-height: 600px;
}
.rbc-month-row {
  overflow: initial;
}
.rbc-event {
  min-height: 25px;
  padding: 3px 7px;
  text-transform: capitalize;
}

.rbc-calendar .rbc-event-content {

  white-space: pre-wrap;
  overflow-y: hidden;
  height: 18px;
  /*height: 30px;*/
  overflow-x: hidden;

}

.ant-calendar-picker-icon {
  z-index: 0;
}

.ant-calendar-picker-input {
  height: 38px;
}

.compromisso_title_container {
  clear: left;
}

#clientes_container {
  margin: 10px;
  text-transform: uppercase;
}
#clientes_container label {
  margin-right: 10px;
}
#clientes_container label input {
  margin-right: 10px;
}

/*Loading*/
#loader-background {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

#loader-container {
  margin: 100px auto;
  width: 90%;
  display: table;
  background-color: white;
  border-radius: 5px;
}

#loader-container img {
  margin: 0 auto;
  display: block;
}

#loader-container p {
  text-align: center;
  height: 20px;
}
.welcome {
   padding-top: 2px;
}
.logout {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 30px;
  margin-top: -2px;
  align-items: center;
  justify-content: center;
}
.logout .material-icons{
  padding: 2px;
}
.logout span {
  padding-left: 2px;
}
.notification {
  margin-top: 10px;
  position: relative;
}
.notification .material-icons {
  font-size: 28px;
}
.notification .badge {
  position: absolute;
  right: -10px;
}

.ant-modal-content .notification-item {
  border-bottom: 3px  solid;
  border-image-source: linear-gradient(135deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%);
  border-image-slice: 1;
  padding-bottom: 10px;
  margin: 15px;
  position: relative;
}

.ant-modal-content .notification-item .delete,.ant-modal-content .notification-item .keep {
  position: absolute;
  right: 0px;
  top: 0px;
}

.ant-modal-content .reg {
  margin-right: 10px;
}

.ant-modal-content .subject {
  font-weight: bold;
}
.ant-modal-content .notification-item .btn {
  padding: 5px 9px;
}
.ant-modal-content .notification-item .more-details {
  margin-left: 35%;
}
.ant-modal-content .notification-item .hide-details {
  margin-left: 40%;
}
.ant-modal-content .notification-item .cap {
  text-transform: capitalize;
}

/*Painel Superior Graficos Compromissos*/
#chart-container {
  /*display: table;
  margin-left: 500px;
  width: 50%;
  background-color: transparent;
  border: none;*/
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  overflow-x: scroll;
  margin: 10px 0px 10px 0px;
}

#top-chart-container {
    display: block;
    background-color: transparent;
    border: none;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 135px;
    overflow-x: scroll;
}

.agenda .add-event {
  margin-top: 20px;
  width: 100px;
}

.agenda-container h1{
  text-align: center;
    font-size: 16px;
    padding: 8px;
    background: #091F5C;
    color: white;
    margin: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.publicacoes-container h1 {
  background: #D6D6D6;
  text-align: center;
  font-size: 16px;
  padding: 8px;
  /*margin: 0;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.financeiro-container h1 {
  background: #2F6EBA;
  text-align: center;
  font-size: 16px;
  padding: 8px;
  color: white;
  margin: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.finance .outgoing-item .fa-trash, .finance .incoming-item .fa-trash {
  top: -20px;
  right: -15px;
}

.ant-calendar-picker {
  width: 100%;
}

.chart-inner {
  width: 100%;
  background-color: transparent;
  border: none;
  min-height: 245px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#chart-container .chart-inner {
  height: 220px;
  min-height: 230px;
}

#chart-container .calendar-widget {
  padding-top: 0px;
  border: 1px solid #ddd;
  z-index: 1;
}

.agenda .ant-tabs{
  margin-top: 40px;
}

.rounded-container {
  border: 1px solid #091F5C;
  border-radius: 10px;
  margin-left: 50px;
  display: flex;
  flex-direction: row;
}

.donut-chart {
  cursor: pointer;
}

.rounded-container > .donut-chart {
  width: 150px;
}

.rounded-container > .donut-chart:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.rounded-container > .donut-chart:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chart-loader {
  position: absolute;
  z-index: 5;
  left: 125px;
  top: 120px;
  width: 50px;
  height: 50px;
}

.div-loading {
  background: rgba(255, 255, 255, 0.8);
  z-index: 4;
  position: absolute;
  width: 300px;
  height: 216px;
}

.agenda-container, .financeiro-container {
  border: 1px solid #091F5C;
  border-radius: 10px;
  margin-left: 15px;
  width: 300px;
  display: table;
  position: relative;
  overflow:hidden;
  /*width: 30%;*/
  /*height: 256px;*/
}

.publicacoes-container {
  border: 1px solid #091F5C;
  border-radius: 10px;
  margin-left: 2%;
  width: 180px;
  display: table;
  overflow: hidden;
}

.publicacoes-container .donut-chart {
  /*margin-top: 25px;*/
  /*border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;*/
  /*width: 150px;
  margin-left: 1px;
  margin-bottom: 6px;*/
  height: 200px;
}

.agenda-container > div, .financeiro-container > div {
  display: flex;
  flex-direction: row;
}

.agenda-container > div > .donut-chart, .financeiro-container > div > .donut-chart {
  width: 50%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 200px;
}

#top-chart-container .calendar-widget {
  width: 15%;
}

.calendar-widget {
    padding-top: 20px;
    color: rgb(0, 161, 202);
    background-color: #fff;
    border-radius: 10px;
    min-width: 180px;
    width: 180px;
    padding-bottom: 37px;
    display: table;
}

.calendar-widget div {
    text-align: center;
    font-weight: bold;
    width: 100%;
    margin-bottom: -20px;
}

.calendar-widget .month{
    font-size: 24px;
}

.calendar-widget .day{
    font-size: 50px;
}

.calendar-widget .year{
    font-size: 25px;
}
.calendar-widget .week{
    position: relative;
    top: 10px;
}
.calendar-widget .users {
  margin-top: 50px;
  color: #666;
}
.calendar-widget .user {
  margin-top: 10px;
  width: 80%;
}

.calendar-widget .user  {
  height: 30px;
}

.coming {
  position: absolute;
  top: -20px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: none;
}

.publicacoes-container {
  position: relative;
  border: 1px solid #091F5C24;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.future-container{
  width: 99.5%;
  height: 100%;
  background-color: #fdfbfbcc;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
}

 .chart-box {
  position: relative;
  margin-top: -8px;
}

.chart-inner h2 {
  display: block;
  text-align: center;
  color: #666;
  font-size: 15px;
  margin-top: 14px;
}

.chart-box-pub {
  height: 150px;
}

.chart-inner .chart-box .chart {
  position: absolute;
  top: 13px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 95px;
  height: 120px;
  color: #666;
  flex-direction: column;
  display: flex;
}

.chart-inner .chart-box .chart .number {
  height: 20px;
}

.chart-inner .chart-box .chart .total {
  font-size: 14px;
  height: 20px;
}

#chart-container .compromissos .chart-box .chart .number, #chart-container .compromissos .chart-box .chart .total  {
  /*margin-left: -17px;*/
}

/*#chart-container .compromissos .info {
  margin-left: -10px;
}

#chart-container .tarefas .info {
  margin-left: -10px;
}*/

#chart-container .tarefas .chart-box .chart .number, #chart-container .tarefas .chart-box .chart .total  {
  /*margin-left: -10px;*/
}

#chart-container .calendar-widget .month {
  font-size: 28px;
}

#chart-container .calendar-widget .day {
  font-size: 48px;
}

#chart-container .calendar-widget .year {
  font-size: 23px;
}

#chart-container .calendar-widget .users {
  margin-top: 46px;
}

.chart-inner .info{
  color: #666;
  text-align: center;
  width: 100%;
  font-weight: bold;
  position: relative;
  top: -20px;
  font-size: 13px;
  margin-top: 0px;
}

.chart-inner .info .atrasados {
  color: red;
  font-weight: bold;
}

.chart-inner .info .hoje {
  color: #04d004;
  font-weight: bold;
}

.chart-inner .info .futuros {
  color: orange;
  font-weight: bold;
}

.chart-inner .info span {
  margin-right: 5px;
}

.chart-inner .calendar .previous, .chart-inner .calendar .next {
  font-size: 52px;
  float: left;
  width: 20%;
  margin-top: -70px;
  cursor: pointer;
}

.chart-inner .calendar .previous {
  margin-left: 20px;
}

.chart-inner .calendar .next {
  margin-left: 185px;
}


/*Painel de Tarefas*/
.panel-tasks .tarefa {
  border: none;
  border-left: 3px solid #33353F;

}

.panel-tasks .atrasada {
  border-color: red;
}

.panel-tasks .pendente {
  border-color: orange;
}

.panel-tasks .concluida {
  border-color: green;
}

.panel-tasks .panel-body {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.orange {
  color: orange;
}

.green {
  color: green;
}

.toggle{
  --uiToggleSize: var(--toggleSize, 20px);
  --uiToggleIndent: var(--toggleIndent, .4em);
  --uiToggleBorderWidth: var(--toggleBorderWidth, 2px);
  --uiToggleColor: var(--toggleColor, #000);
  --uiToggleDisabledColor: var(--toggleDisabledColor, #868e96);
  --uiToggleBgColor: var(--toggleBgColor, #fff);
  --uiToggleArrowWidth: var(--toggleArrowWidth, 2px);
  --uiToggleArrowColor: var(--toggleArrowColor, #fff);

  display: inline-block;
  position: relative;
}

.toggle__input{
  position: absolute;
  left: -99999px;
}

.toggle__label{
  display: inline-flex;
  cursor: pointer;
  min-height: var(--uiToggleSize);
  padding-left: calc(var(--uiToggleSize) + var(--uiToggleIndent));
}

.toggle__label:before, .toggle__label:after{
  content: "";
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  font-size: var(--uiToggleSize);

  position: absolute;
  left: 0;
  top: 0;
}

.toggle__label:before{
  border: var(--uiToggleBorderWidth) solid var(--uiToggleColor);
  /*z-index: 2;*/
}

.toggle__input:disabled ~ .toggle__label:before{
  border-color: var(--uiToggleDisabledColor);
}

.toggle__input:not(:disabled) ~ .toggle__label:after{
  background-color: var(--uiToggleColor);
  opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label:after{
  opacity: 1;
}

.toggle__text{
  margin-top: auto;
  margin-bottom: auto;
}

/*
The arrow size and position depends from sizes of square because I needed an arrow correct positioning from the top left corner of the element toggle
*/

.toggle__text:before{
  content: "";
  box-sizing: border-box;
  width: 0;
  height: 0;
  font-size: var(--uiToggleSize);

  border-left-width: 0;
  border-bottom-width: 0;
  border-left-style: solid;
  border-bottom-style: solid;
  border-color: var(--uiToggleArrowColor);

  position: absolute;
  top: .5428em;
  left: .2em;
  z-index: 3;

  transform-origin: left top;
  transform: rotate(-40deg) skew(10deg);
}

.toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text:before{
  width: .5em;
  height: .25em;
  border-left-width: var(--uiToggleArrowWidth);
  border-bottom-width: var(--uiToggleArrowWidth);
  will-change: width, height;
  transition: width .1s ease-out .2s, height .2s ease-out;
}

.finance-lawsuit-item .description {
  padding-left: 20px;
}

#finance-table .category {
  width: 12%;
}

/*
=====
LEVEL 2. PRESENTATION STYLES
=====
*/

/*
The demo skin
*/

.toggle__label:before, .toggle__label:after{
  border-radius: 2px;
}

/*
The animation of switching states
*/

.toggle__input:not(:disabled) ~ .toggle__label:before,
.toggle__input:not(:disabled) ~ .toggle__label:after{
  opacity: 1;
  transform-origin: center center;
  will-change: transform;
  transition: transform .2s ease-out;
}

.toggle__input:not(:disabled) ~ .toggle__label:before{
  transform: rotateY(0deg);
  transition-delay: .2s;
}

.toggle__input:not(:disabled) ~ .toggle__label:after{
  transform: rotateY(90deg);
}

.toggle__input:not(:disabled):checked ~ .toggle__label:before{
  transform: rotateY(-90deg);
  transition-delay: 0s;
}

.toggle__input:not(:disabled):checked ~ .toggle__label:after{
  transform: rotateY(0deg);
  transition-delay: .2s;
}

.toggle__text:before{
  opacity: 0;
}

.toggle__input:not(:disabled):checked ~ .toggle__label .toggle__text:before{
  opacity: 1;
  transition: opacity .1s ease-out .3s, width .1s ease-out .5s, height .2s ease-out .3s;
}

/*
=====
LEVEL 3. SETTINGS
=====
*/

.toggle{
  --toggleColor: green;
  --toggleBgColor: green;
  --toggleSize: 20px;
  margin-left: 40px;
}

#schedule-lawsuit-target .btn-primary {
  margin-bottom: 10px;
  padding: 6px 20px;
  font-size: 13px;
}

.small {
  font-size: 12px;
}

.small-first {
  margin-top: 20px;
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

#compromisso-container .status {
  /*width: 25%;*/
}

.agenda .ant-tabs {
  margin-bottom: 20px;
}
.rbc-month-view, .rbc-time-view {
  height: 95%;
  min-width: 934px;
}

.only-edit #bt-edit-dados-processo {
  left: 45px;
}

.edit-user {
  width: 45px;
}
/* Doc Fácil */

.btn-modelo {
  margin-left: 40%;
  margin-top: 20px;
}

.manager-template h1{
  color: #666;
}

.manager-template .panel-primary, .generate-document .panel-primary, .list-template .panel-primary{
  margin: 25px auto 0 auto;
  width: 95%;
}

.config-user .panel-primary, .config-logo .panel-primary, .config-office .panel-primary, .report .panel-primary {
  margin: 20px auto;
  width: 95%;
}

.manager-template .panel-heading, .finance .panel-heading, .generate-document .panel-heading, .list-template .panel-heading{
  padding: 17px;
}

.config-user .panel-heading, .report .panel-heading {
  padding: 17px;
}

.manager-template h3{
  color: #fff;
}

#edit-model {
  margin-top: 20px;
  margin-bottom: 20px;
}

.edit-template .content-header{
  width: 60%;
  float: left;
}

.editor {
  margin-top: 10px;
  height: 600px;
  margin-bottom: 20px;
}

.ql-container {
  height: 90%;
  font-size: 16px;
}

.sidebarPartes {
  float: left;
  width: 30%;
  margin-top: 40px;
  margin-left: 3%;
  font-size: 15px;
}

.sidebarPartes .fields {
  cursor: pointer;
}

.sidebarPartes .fields button {
  width: 100%;
  background: transparent;
  border: 0;
  text-align: left;
}

.panel-success {
  margin-bottom: 10px;
}

.panel-success .panel-heading {
  padding: 7px;
}

.panel-success .panel-title {
  font-size: 14px;
}

.view-editor {
  width: 80%;
}

.view-template .ql-toolbar.ql-snow{
  border-bottom: 0;
}

.view-template .quill{
  margin: 20px auto;
  width: 80%;
}

.btn-info {
  margin-top: 10px;
  margin-bottom: 10px;
}

.generate-document .document {
  margin-left: 15px;
  width: 94%;
}

.generate-document .btn {
  margin-right: 15px;
  margin-top: 10px;
}

.partes-container{
  display: table;
  width: 100%;
}

.remove-component{
  position: relative;
  top: 30px;
  right: 5px;
}

.parte-error-item{
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.document-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-info p {
  margin-bottom: 5px;
  font-size: 13px;
}

.document-info .container-info {
  width: 75%;
}

.document-info .link b {
  font-size: 15px;
  display: block;
  padding-left: 10px;
}

.document-info .link {
  height: 50px;
  border-left: 1px solid #3c763d;
  vertical-align: middle;
  display: block;
  align-items: center;
  justify-content: center;
  display: flex;
}

.alert-dismissable .close, .alert-dismissible .close {
  right: 10px;
  top: 5px;
  position: absolute;
}

#addParteExtra {
  margin-left: 16px;
}

.generate-document .alert {
  margin-left: 15px;
  margin-right: 15px;
  padding: 2px;
  padding-left: 20px;
  position: relative;
}

b.title { font-size: 15px; margin-left: 5px;}

/* Finance */

.content-header {
  padding-left: 15px;
}

.container-search {
  /*margin-right: 15px;*/
  float: right;
}

.finance .container-search {
  margin-right: 15px;
}

.finance .ant-calendar-picker, .publications .ant-calendar-picker{
  margin-left: 10px;
  width: 250px;
}

#lancamento-container .form-group {
  min-height: 85px;
}

#lancamento-container .errorMsg {
  margin-bottom: 0px;
}

.skin-black .sidebar-form {
  font-size: 10.7px;
}


.header-controls-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.ant-modal-wrap {
  z-index: 1200;
}
.ant-calendar-picker-container {
  z-index: 1250;
}

.publications .filter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.publications .forma-pagamento-container {
  font-size: 12px;
}

.finance .filter .date, .finance .filter .fields, .publications .filter .date, .publications .filter .fields  {
  float: left;
  margin: 10px;
}

.afiliados .filter .date  {
  float: left;
  margin: 20px;
  width: 200px;
}

.afiliados .container-search {
  margin-top: 40px;
  float: left;
}

.finance .filter .fields, .publications .filter .fields {
  width: 77%;
  margin-top: -28px;
}

.baixa {
  width: 280px;
}

.centro-custo-container {
  width: 280px;
}

.forma-pagamento-container {
  width: 280px;
}

.publications .forma-pagamento-container {
  width: 260px;
}

.cliente-container {
  width: 343px;
}

.lawsuit-container {
  width: 280px;
}

.publications-container-search {
  margin-top: 10px;
}

.finance .container-search .btn, .publications-container-search .btn {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 0px;
}

/*.add-incoming {
  margin-left: 20px;
}*/

.incoming-item td, .outgoing-item td {
  padding-bottom: 12px;
  padding-top: 12px;
}

.incoming-item, .outgoing-item, .cashflow-item {
  font-size: 13px;
  height: 52px;
}

.incoming-item .cliente {
  font-size: 12px;
}

.finance .box-title {
  display: block;
}

.finance thead > tr > th {
  padding-left: 8px;
}

.finance .table_description, .finance .table_cliente, .finance .table_lawsuit{
  width: 22%;
}

.finance table .actions .baixado {
  top: 10px;
}

.green {
  color: green;
}

.btn-print {
  padding: 12px 9px;
  /*margin-left: 10px;*/
  font-size: 13px;
}

.label-total {
  margin-left: 20px;
}

.centroCustoSelect, .categorySelect {
  width: 79%;
  float: left;
  margin-right: 10px;
}

.select-container {
  width: 274px;
  float: left;
}

.switch-container {
  min-width: 274px;
  float: left;
}

.label-total {
  border: 1px solid #666;
  border-radius: 5px;
  padding: 7px;
  margin: 10px;
  display: inline-block;
}

#dados-pagamento {
  border-top: 2px solid #666;
  border-bottom: 2px solid #666;
}

#lancamento-container .title-container {
  min-height: 50px;
}

.list-contas-pagar .filter .fields {
  margin-top: -10px;
}

.list-contas-pagar .container-search {
  margin-top: 0px;
}

.toggle__title {
  margin-left: -7px;
}

.curi .toggle__input:not(:disabled) ~ .toggle__label:before {
    -webkit-transform: none;
    transform: none;
}

.curi {
  border: green solid;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: -7px;
}

.finance .table_actions, .table_actions {
  text-align: right;
  padding-right: 35px;
}

#document-table .title {
    width: 50%;
}

.finance .row, .publications .row{
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
}

.since-label {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgba(0, 0, 0, 0.85);
}

.since input {
  padding-left: 15%;
  margin-left: 10px;
}

.resultado {
  font-size: 14px;
}

.resultado-saldo {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  min-height: 33px;
  width: 100%;
  line-height: 25px;
  font-size: 15px;
}

.cashflow .table_description, .table_description {
  width: 30%;
}
.cashflow .table_category {
  width: 18%;
}
.cashflow .table_data, .cashflow .table_debito, .cashflow .table_credit {
  width: 10%;
}

.align-right {
  float: right;
}

.valor-total {
  float: right;
  margin-right: 80px;
  font-weight: bold;
  margin-top: 10px;
}

.credit, .debito {
  text-align: right;
  /*padding: 16px 16px;*/
}

.finance-lawsuit-item .credit, .finance-lawsuit-item .debito {
  text-align: initial;
}

.credit {
  color: green;
}

.debito {
  color: red;
}

.resultado-acumulado, .resultado-periodo {
  text-align: left;
  font-weight: bold;
}

.table_debito, .table_credit, td.total, .resultado-periodo {
  text-align: right;
}

.total {
  font-weight: 700;
}

.relative {
  position: relative;
}

/* Finance Lawsuit */

#details-lawsuit .number_lawsuit span {
  font-size: 11.9px;
}

#details-lawsuit #finance-table{
  margin-bottom: 30px;
}

.finance-lawsuit .select-container {
  width: 200px;
  padding-left: 15px;
}

.finance-lawsuit-item .date {
  vertical-align: middle;
}

.top{
  top: 250px !important;
}

.switches {
  margin-top: 10px;
  margin-bottom: 10px;
}

.subtitle {
  background-color: #eee;
  padding: 12px;
  font-weight: bold;
}

table .type, table .valor {
  min-width: 100px;
}

/* Config User */
.disabled {
  color: #eee;
}

.config-user .box-body {
  padding-top: 0px;
}

.config-user table {
  margin-top: 15px;
}

.config-user thead > tr > th, .config-user table .last {
  text-align: center;
  font-size: 11px;
}

.config-user thead > tr > th {
  padding: 6px 3px;
}

.config-user thead > tr > th p {
  margin-bottom: 2px;
}

.config-user thead > tr > th img.help_icon{
  display: block;
  margin-left: 40%;
  margin-top: 5px;
}

.config-user table .table_user{
  width: 20%;
  text-align: left;
}

.config-user table .table_config{
  width: 10%;
}

.config-user table .ant-switch {
  margin-left: 25%;
}

.user-item a {
  color: #595959;
}

.switchSegredoJustica {
  margin-left: 23%;
}

.hours {
  color: #000;
  padding: 20px 8px 20px 8px;
  cursor: pointer;
}

.liberado {
  background-color: #90e0a9;
}

.restrito {
  background-color: #e2afaf;
}

#user_week_days{
  width: 580px;
}

#user_week_days button + button {
  margin-left: 0px;
  border-left: 0px;
}

#segredo-justica{
  padding: 0px;
}

.perfil .content-wrapper-inner {
  display: table;
}

.perfil .switchSegredoJustica {
  margin-left: 7%;
}

#segredo-justica .btn-info{
  padding: 8px;
  font-size: 14px;
}

.config-level .ant-tabs .panel {
  width: 280px;
  float: left;
  margin: 20px;
  min-height: 300px;
}

.config-level .ant-tabs .panel .panel-title{
  font-size: 14px;
}

.config-level .ant-tabs .panel p{
  font-size: 13px;
}

.lista_permissoes {
  padding-left: 0px;
}

.lista_permissoes li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 13px;
}

.lista_permissoes li span:first-child{
  width: 70%;
  display: inline-block;
}

.lista_permissoes_processo li span:first-child{
  width: 50%;
  display: inline-block;
}
.lista_permissoes_processo li .acesso, .lista_permissoes_processo li .excluir{
  text-align: center;
}
.lista_permissoes_processo li .excluir b{
  margin-left: 10px;
}
.lista_permissoes_processo li .excluir, .lista_permissoes_processo li .acesso, .lista_permissoes li .acesso{
  width: 25%;
  display: inline-block;
}

.ged_documentos_do_escritorio-container {
  display: none;
}

/*Cabeçalho e logomarca*/
.logo-form, .perfil-form {
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 340px;
  float: left;
  padding: 10px;
}

.logo-form * {
  margin-top: 10px;
}

.imagem_salva{
  width: 150px;
  border: 1px solid #ddd;
  margin-left: 26%;
}

.logo-form button {
  margin-left: 27%;
}

.config-logo .editor {
  width: 60%;
  float: left;
  height: 150px;
  margin-left: 20px;
  margin-top: 0px;
}

#saveHeader {
  float: right;
  margin: 20px;
  padding: 10px;
  margin-right: 55px;
}

/*Meus dados*/
.config-office .panel-primary {
  margin-top: 10px;
  margin-bottom: 10px;
}

.config-office .panel-heading {
  padding: 20px;
}

.config-office h5 {
  font-weight: bold;
  padding: 15px;
  background-color: #eee;
  float: left;
  width: 100%;
  margin-top: 10px;
}

.config-office .pull-right {
  position: initial;
}

.ant-progress-line {
  width: 84%;
  margin-left: 5%;
}

.config-level .panel-heading > .pointer:after{
  content: "";
}

.change-plan {
  margin-left: 60px;
  padding: 7px 20px;
  font-size: 14px;
}

.change-plan-screen .service-title {
  background: linear-gradient(135deg, rgba(0,101,148,1) 0%, rgba(0,126,148,1) 26%, rgba(15,207,255,1) 100%);
  color: white;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 470px;
}

.change-plan-screen .service-title p {
  padding-top: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.change-plan-screen .row {
  margin-left: 15px;
  margin-right: 15px;
  margin: 0 auto;
  width: 97%;

}

.cancel-loading {
  margin: 10px;
}

/*Configurações Tribunal */

.config-tribunal input[type='checkbox']{
  margin-right: 15px;
  transform: scale(1.15);
}

.tribunal-list li a {
  float: right;
  width: 97%;
}

.independent{
  float: none;
  width: initial;
}

.update-in{
  font-size: 12px;
}

/* Reports */

.report-event h5 {
  font-weight: bold;
  padding: 15px;
  background-color: #eee;
  float: left;
  width: 100%;
  margin-top: 10px;
}

.report-event .control-label {
  width: 100%;
}

.view-report {
  margin-top: 10px;
  margin-right: 10px;
}

#filter_target ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /*grid-gap: 30px;*/
}

.financeList #filter_target ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.btn-report {
  margin: 10px 2px 10px 3px;
  font-size: 14px;
  margin-right: 15px;
}

.btn-excel {
  margin-right: 15px;
}

.btn-back {
  margin: 0;
}

#view-dados-topo label{
  margin-right: 10px;
}

#view-dados-topo div {
  min-height: 34px;
}

#view-dados-topo .beneficio {
  margin-top: 6px;
}

.tab-title {
  /*margin-right: 20px;*/
}

.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}

.span_title {
  margin-left: 20px;
}

.partes-only-table {
  table-layout: initial;
}

.only .actions {
  width: 50px;
}

.only .actions .ant-divider {
  left: 100px;
}

.only .actions .pull-right {
  left: 40px;
}

.ant-tabs-tab-active {
  background-color: #eee;
  border-radius: 5px;
}

.report .rbc-toolbar {
  justify-content: left;
}
.report .rbc-btn-group {
  padding-left: 15px;
}
.report .rbc-toolbar {
  font-size: 14px;
}

.report-client-config-exibition .toggle {
  margin-left: 0px;
}

#reportClientList thead > tr > th{
  padding: 16px 0;
}

#reportClientList tbody > tr > td{
  padding: 0;
}

#reportClientList td:first-child, #reportClientList th:first-child {
  padding-left: 10px;
}

.reportClientList .endereco {
  width: 30%;
}

.size-6, .size-7 {
  font-size: 12px;
}
.size-9, .size-8 {
  font-size: 11px;
}
.size-6 .endereco, .size-7 .endereco {
  width: 20%;
}
.size-8 .endereco, .size-9 .endereco {
  width: 16%;
}

.switch-details {
  margin-top: 20px;
}

.switch-details label {
  padding-left: 15px;
}

table table thead > tr > th {
  background: #fff;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
}

.tcredito {
  color: #3c763d;
}
.tdebito {
  color: #a94442;
}

.report-lawsuit .toggle {
  margin-left: 0px;
}

.report-lawsuit .row {
  /*margin-bottom: 10px;*/
}

.report-lawsuit .panel-body {
  word-break: break-all;
}

#filter-tribunals-target .btn {
  padding: 5px;
  font-size: 14px;
  margin: 15px;
}

#filter-tribunals-target .panel-heading, .config-tribunal .panel-heading {
  position: relative;
}

.config-tribunal .panel-heading {
  padding: 10px;
}

#filter-tribunals-target .panel-heading input[type='checkbox'], .config-tribunal .panel-heading input[type='checkbox']{
  position: absolute;
  left: 10px;
}

#filter-tribunals-target .panel-title, .config-tribunal .panel-title {
  margin-left: 10px;
}

#filter-tribunals-target label,.config-tribunal .panel-body label {
  height: 45px;
}

#filter-datas-target .date_andamento span+label, #filter-datas-target .date_andamento label+span{
  margin-left: 10px;
}

#config-show-target .line {
  margin-bottom: 10px;
  clear: both;
}

#config-show-target .line .space-top {
  margin-top: 7px;
}
#config-show-target .line .sub {
  width: 50%;
  float: left;
  margin-bottom: 15px;
}
#config-show-target .line .lb {
  margin-top: 7px;
  float: left;
  min-height: 36px;
}
#config-show-target .line .sub label:first-child {
  width: 60%;
}
#config-show-target .line .space-top *{
  float: left;
  margin-right: 10px;
}

.doCliente {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 15px;
  width: 100%;
}

#config-show-target .cliente label{
  width: 50%;
}

.linha-top {
  border-top: 1px dashed #ccc;
  display: table;
  width: 100%;

}

.data-movement .row, .data-rai .row, .data-compromissos .row, .data-publicacoes .row {
  margin-left: 0%;
  margin-right: 0%;
}

.date-container {
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 10px;
  width: 49%;
  float: left;
  margin-right: 1%;
  display: flex;
  margin-bottom: 10px;
  min-height: 69px;
}

.date-container>div {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
   align-items: center;
}
.date-container>div label{
  word-break: break-word;
  min-width: 60px;
  align-self: center;
}
.date-container>div span{
  word-break: break-word;
}

.report-lawsuit .endereco, .report-lawsuit .telefones, .report-lawsuit .emails {
  font-size: 13px;
}

.report-lawsuit .data-client div {
  min-height: 25px;
}

.reportEventList .add-event, .panel-tasks .add-event, .reportFinanceList .add-incoming, .reportFinanceList .add-outgoing {
  display: none;
}

.panel-tasks .btn {
  padding: 5px 11px;
  font-size: 14px;
}

.btn-contrato {
  position: absolute;
  right: 30px;
  top: -2px;
  font-size: 14px;
  padding: 8px;
}

.reportEventList .panel .actions .pull-right {
    left: 10px;
}

.both {
  clear: both;
}

.event-green {
  color: #00875A;
}

.event-blue {
  color: #0052CC;
}

.event-red {
  color: #FF5630;
}

table p {
  margin-bottom: 5px;
}

.reportEventList table {
  font-size: 13px;
}

.reportEventList thead > tr > th {
  padding: 10px 16px 5px 10px;
}

.reportEventList .category {
  font-size: 10px;
}

.report-finance-item .date, .reportEventList table .date {
  vertical-align: middle;
  width: 10%;
}

.reportEventList .table_lawsuit_number {
  width: 22%;
}

.reportEventList table .description {
  width: 25%;
}

.filter-tribunals {
  margin-left: 39px;
  margin-bottom: 15px;
}

#send-email-group .help_icon {
  margin-top: 13px;
}

#balaozinho {
  position: inherit;
}

.react-tiny-popover-container .settings {
  font-size: 14px;
}

.copyright {
  color: #fff;
  margin-left: 19px;
  bottom: 10px;
  position: absolute;
  overflow: hidden;
}

.sidebar-collapse .copyright {
  display: none;
}

.new-footer {
  position: absolute;
  bottom: 0px;
  right: 25px;
  text-align: center;
  display: none;
}

.info-task-important {
  display: table;
  margin-left: 22px;
}

.agenda-evento-info {
  border-top: 2px solid #ccc;
  clear: both;
  margin-top: 15px;
  margin-left: 22px;
  padding-top: 10px;
  display: table;
}

.notification-item-details {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}

.agenda-evento-info div {
  height: 40px;
  overflow: hidden;
}

.perfil .box-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.perfil #handleOk {
  margin-top: 30px;
}

.perfil #image_perfil {
  width: 90px;
  height: 90px;
  display: block;
}

.perfil .imagem_salva {
  margin-left: 36%;
}

.perfil-form {
  margin-top: 30px;
}

.dimensoes-imagem {
  margin-top: 20px;
  margin-bottom: 20px;
}

#conpass-tag button.conpassAssistant.bottom-left {
  left: 65px !important;
  bottom: 5px !important;
}

#conpass-tag button.conpassAssistant.bottom-left img {
  width: 48px !important;
  height: 48px !important;
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  font-size: 14px;
  padding: 14px;
}

.ant-tabs {
  font-size: 12px;
}

.ant-tabs-bar {
  margin: 0 0 5px 0;
}

.alert {
  margin-bottom: 5px;
}

.my-alert-box {
  padding-top: 50px;
  padding-left: 12px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.alert-action {
  align-self: center;
}

.confirm_dialog button, .alert_dialog #cancel {
  padding: 10px 15px 10px 15px;
  margin: 20px;
  height: auto;
}

.confirm_dialog #confirm, .alert_dialog #cancel{
    background-color: #1890ff;
    padding: 10px 30px 10px 30px;
}

.my-alert-box h3 {
  font-size: 20px;
}

.futureOptions {
  margin-top: 10px;
}

.add-publication {
  padding: 5px;
  margin-left: 25px;
  font-size: 14px;
}

.publication-item .fa-trash {
  position: initial;
  top: initial;
  width: initial;
}

.tribunal .filtro {
  padding: 15px;
  width: 50%;
  margin-left: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#regioes {
  clear: both;
  margin-left: 20px;
  min-width: 150px;
  padding: 12px;
  -webkit-appearance: none;  
   -moz-appearance: none; 
   appearance: none; 
   background: url(https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS3-ywUVHBNam3DS8gXfLDGYf7lpS98npeAhw&usqp=CAU) no-repeat;  
   background-position: right 10px;
   background-size: 30px;  
   border-radius: 5px;
}

#tribunalOptions {
  clear: both;
  min-width: 300px;
  padding: 15px;
  /*-webkit-appearance: none;  
   -moz-appearance: none; 
   appearance: none; 
   background: url(https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS3-ywUVHBNam3DS8gXfLDGYf7lpS98npeAhw&usqp=CAU) no-repeat;  
   background-position: right 10px;
   background-size: 30px;*/
}

.table-hover .segredo-item:hover {
  background-color: #fcfcfc;
}

.segredo-item .fa-trash {
    right: 30%;
}

.pub-item {
  font-size: 12px;
}

span.back-top{
  padding:5px;
  position:fixed;
  top: 85%;
  right:75px;
  font-size: 30px;
  color: #666;
  border: 1px solid #666;
  cursor: pointer;
  background: #fff;
  z-index: 10000;
  display: none;
}
span.back-top:hover{
  text-decoration:none;
}

.count {
  width: 5%;
}

#alterar {
  margin-top: 20px;
}

.email-container {
  height: 73px;
}

.md-chat-widget-btn-container {
  width: 40px;
}

.md-chat-widget-btn-container svg{
  height: 24px;
  margin-top: 3px;
}

/* Afiliados */
.input-icons .hashtag-icon { 
  position: absolute;
  padding: 6px; 
  min-width: 40px; 
  text-align: center;  
  background-color: #f0f0f0;
  font-weight: bold;
  margin-left: 2px;
  margin-top: 2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
} 

.input-icons { 
  margin-bottom: 10px; 
} 

.input-field {
  padding-left: 50px;
}

.clientFilter__option:first-child{
  color: green !important;
}

@media (min-width: 490px){
  .panel-heading strong{
    width: initial;
    display: inline-block;
  }

  .finance .ant-calendar-picker, .publications .ant-calendar-picker{
    margin-left: 10px;
    width: 130px;
  }

  .baixa {
    width: 160px;
  }

  .centro-custo-container {
    width: 145px;
  }

  .contas-pagar-container .lawsuit {
    width: 40%;
  }
}

@media (max-width: 767px){
  #top-chart-container {
    width: 90%;
  }
}

@media (min-width: 768px){
  .menu-topo-processo {
    flex-direction: row;
  }
  .welcome {
    margin-top: 10px;
    margin-left: 42px;
    margin-right: 20px;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: 700;
    font-size: 17px;
    width: 60%;
  }
  .tribunal-menu .treeview .tribunal-name {
    width: 263px;
    /*width: 310px;*/
  }
  .tribunal-menu .nav-text .tribunal-name {
    width: 325px;
  }
  .sheet {
      margin-top: 70px;
  }
  .horizontal-space {
    margin-left: 20px !important;
  }
  .home-menu .pull-right {
    right: 31px;
    top: 15px;
  }
  .modal-dialog {
      width: 450px;
  }
  .home-menu .treeview-menu {
      padding-left: 45px;
      padding-right: 45px;
  }
  .home-menu .treeview-menu > li {
      padding: 5px;
  }
  #top-chart-container {
    margin: 0 auto;
    margin-top: 135px;
    width: 708px;
  }
  .container-input-pesquisa {
    width: 32%;
    display: inline-block;
    margin-right: 2%;
  }

  #opt_pesquisa {
    width: 45%;
  }

  .perfil .content-wrapper-inner {
    max-width: 990px;
  }
  table .fa-trash {
    top: -22px;
  }
  .finance-lawsuit-item .fa-trash {
    top: initial;
  }
  .actions .toggle {
    margin-left: 0px;
  }
  .ged-download-button {
    right: 0px;
    left: 30px;
    top: 0px;
    position: relative;
  }
  #movement-table .andamentos, #publications-table .publication span, .container-publicacoes .publication span, #info-table .informacoes span, #schedule-table .description span   {
    width: 553px;
  }

  .new-footer {
    margin-left: 45%;
    right: initial;
  }

  .new-footer {
    display: block;
  }

  #loader-container {
    width: 500px;
  }

  .ant-modal-content .rbc-btn-group button + button {
    margin-left: 10px;
    width: 125px;
  }
  #compromisso, #tarefa, #notificar {
    width: 145px;
  }

  .ant-modal-body {padding: 24px;}


  /*#send-email-group {width: 86%;display: inline-flex;margin-left: 50px;}
  #send-email-group label {width: auto; margin-left: 15px;margin-right: 15px; margin-top: 10px}
  #send-email-group .ant-switch {margin-top: 10px}
  #send-email-group .quando_faltar {width: 54%;}*/

}

@media (min-width: 994px){

  .movement-insert {
    top: 10px;
  }
  .welcome {
    width: 45%;
  }
  .lawsuit_search, .blank {
    width: 466px;
    display: flex;
  }
  .col-md-3 {
      width: 25%;
  }
  #top-chart-container {
    /*margin-left: 270px;*/
  }

  .list-lawsuit .form-pesquisa-tribunal .btn {
    width: 88px;
  }

  #movement-table .andamentos, #publications-table .publication span, .container-publicacoes .publication span, #info-table .informacoes span, #schedule-table .description span   {
    width: 100%;
  }

  .number_movement {
    width: 7%;
  }

  .fa-comment {
    top: 5px;
    position: relative;
  }

  .resultado {
    position: absolute;
    top: 5px;
    left: 167px;
    font-size: 17px;
  }


  #chart-container {
    display: table;
    width: 550px;
    background-color: transparent;
    border: none;
    right: 30px;
    position: absolute;
    top: 15px;
  }

  .agenda .ant-tabs{
    margin-top: 100px;
  }

  .agenda .add-event {
    margin-top: 50px;
  }

  #typeEventContainer {margin-left: 20%; width: 60%; }

  .formCalendar {
    width: 875px !important;
  }

  #tribunalOptions {
    clear: both;
    margin: 20px 25%;
    min-width: 300px;
    width: 50%;
    padding: 15px;
    /*-webkit-appearance: none;  
     -moz-appearance: none; 
     appearance: none; 
     background: url(https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS3-ywUVHBNam3DS8gXfLDGYf7lpS98npeAhw&usqp=CAU) no-repeat;  
     background-position: right 10px;
     background-size: 30px;*/
  }


  .form-group a.add-lawsuit {
    margin-right: 30px;
    margin-left: 0px;
  }

  .container-input-pesquisa {
    display: inline-block;
  }

  #inp_pesquisa {
    width: 220px;
  }

  #opt_pesquisa {
    width: 23%;
    margin-left: 10px;
  }

  .finance .panel-primary{
    margin-left: 30px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 92%;
  }
  
  .finance .box-body{
    padding: 0px;
    margin-left: 35px;
    margin-right: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 91%;
  }

  .box-body {
    padding: 30px;
  }

  .finance .actions-container {
    float: right;
    position: relative;
    /*right: 15px;*/
  }

  .btn-print {
    margin-left: 5px;
  }


  .finance .container-search {
    margin-top: 10px;
    position: absolute;
  }

  .finance .container-search{
    width: 165px;
    float: right;
    position: relative;
    /*right: 0px;*/
  }

  .outgoing .container-search, .incoming .container-search {
    position: relative;
    right: -16px;
  }

  .lawsuit-container {
    width: 260px;
  }

  .form-add-incoming {
    width: 800px !important;
  }

  .since, .since div{
    width: 175px !important;
  }

  .forma-pagamento-container {
    width: 260px;
  }
  
  .generate-document .content-wrapper-inner, .cashflow .content-wrapper-inner, .edit-template .content-wrapper-inner {
    display: table;
  }

  .inssLabel {
    margin-right: 20px;
    margin-left: 5px;
  }
  .inssRadio {
    padding: 5px;
  }

}

@media (min-width: 1024px){
  table .fa-trash {
    top: 0px;
  }
  .ged-download-button{
    left: 0px;
  }
}

@media (min-width: 1098px){
  .update-in{
    float: right;
    top: -20px;
    position: relative;
    right: 40px;
  }
  .update-in i{
    margin-right: 5px;
  }
  #top-chart-container {
    width: 92%;
  }
}

@media screen and (max-width: 1279px){
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }
}

@media screen and (min-width: 992px) and (max-width: 1107px){
  .welcome {
      display: none;
  }
}


@media (min-width: 1280px){
  .update-in{
    float: right;
    top: -20px;
    position: relative;
    right: 40px;
  }
  .update-in i{
    margin-right: 5px;
  }
  .coming {
    display: block;
  }
  .lista-processos-action-buttons{
    width: 34%;
  }
  .form-pesquisa-tribunal {
    width: 66%;
    margin-left: 0px;
    padding: 0px;
  }
  .container-search .btn:first-child {
    margin-right: 0px;
  }
  .container-label-pesquisa {
    width: 16%; 
  }
  .container-input-pesquisa {
      width: 25%;
  }
  #inp_pesquisa {
    width: 170px;
  }
  .container-search {
    width: 30%;
    margin-left: 5px;
  }
  .container-search .btn {
    margin-left: 4px; margin-right: 3px;
    padding-left: 14px; padding-right: 14px;
  }
  .form-group a.add-lawsuit {
    margin-right: 0px;
  }
  .mySelect__control{
    height: 41px;
   }
  table {
    table-layout: fixed;
  }

  .rbc-agenda-view table {
    table-layout: initial;
  }

  .rbc-agenda-table tr {
    background: transparent !important;
    color: black !important;
  }

  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    border: 1px solid #ddd;
  }

  .finance table{
    table-layout: fixed;
    width: 92%;
    margin-left: 30px;
  }

  .finance .incoming-item .fa-trash, .finance .outgoing-item .fa-trash{
    right: 0px;
    top: 10px;
  }

  .ged-download-button {
    top: 5px;
  }
  .container-publicacoes table {
    table-layout: initial;
  }
  .table_lawsuit_number, .type_personage {
    width: 275px;
  }
  .table_lawsuit_status, .table_pasta {
    width: 148px;
  }
  .table_lawsuit_cliente, .table_lawsuit_oponente, .personage_name {
    width: 25%;
    white-space: nowrap;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  #divclientexoponente {
    text-transform: uppercase;
  }
  .table_lawsuit_actions, .actions, .movement_actions {
    width: 10%;
    text-align: center;
    position: relative;
  }
  table .fa-trash {
    position: absolute;
    top: 10px;
  }

  .actions .pull-right {
    right: initial;
    left: 0px;
    top: 10px;
    position: absolute;
  }

  .fa-comment {
    position: absolute;
    left: 16px;
    top: 15px;
  }

  .md-chat-widget-btn-container {
    width: initial;
  }
  
  .md-chat-widget-btn-container svg{
    height: initial;
    margin-top: initial;
  }

}

@media (min-width: 1334px){
  #top-chart-container {
    overflow-x: initial;
  }
}

@media (min-width: 1440px){
  label {
    margin-bottom: 2px;
  }
  .chart-inner h2 {
    font-size: 17px;
  }
  .agenda-container h1, .financeiro-container h1{
    font-size: 20px;
  }
  .chart-inner .info {
    font-size: 14px;
  }
  .publicacoes-container .info {
      font-size: 12px;
  }
  .publicacoes-container h1 {
    font-size: 17px;
  }
  .calendar-widget .month{
      font-size: 32px;
  }
  .calendar-widget .day{
      font-size: 56px;
  }
  .home-menu .treeview{
    padding: 10px 15px;
    font-size: 16px;
  }
  .panel-primary>.panel-heading {
    padding: 10px 15px;
  }
  .panel {
    margin-bottom: 20px;
  }
  #top-chart-container .help_icon, .header-personage .help_icon, .header-h4 .help_icon {
    position: absolute;
    margin-top: 3px;
  }
  #addPopover {
    margin-top: -12px;
  }
  .agenda-container > div > .donut-chart, .financeiro-container > div > .donut-chart, .publicacoes-container .donut-chart {
    height: 215px;
  }
  .publicacoes-container .donut-chart {
    /*margin-top: 36px;*/
  }
  .calendar-widget .month{
    font-size: 28px;
  }

  .calendar-widget .day{
      font-size: 54px;
  }

  .treeview-menu > li > a {
    padding: 5px 5px 5px 15px;
    font-size: 15px;
  }

  .sidebar-menu > li {
    font-size: 16px;
  }

  .sidebar-menu > li > a {
    padding: 12px 5px 12px 15px;
  }

  #conpass-tag button.conpassAssistant.bottom-left img {
    width: 60px !important;
    height: 60px !important;
  }

  .home-menu .treeview{
    padding: 9px 15px;
  }

  .content-header > h1 {
    font-size: 24px;
  }

  .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
    font-size: 16px;
    padding: 16px;
  }

  tbody > tr > td {
    padding: 5px 8px 20px 8px;
    word-break: break-word;
  }
  .ant-tabs {
    font-size: 14px;
  }

 .ant-tabs-bar {
    margin: 0 0 16px 0;
  } 

  thead > tr > th {
    padding: 16px 16px;
  }

  .alert {
    margin-bottom: 20px;
  }

  .box-body {
    padding: 30px;
  }

  .content-header {
    padding: 15px 15px 0 15px;
    padding-left: 30px;
  }

  .details-lawsuit .box-body .panel-primary .panel-heading {
    font-size: 16px;
  }

  .panel-default {
    margin-top: 20px;
  }

  .panel-heading {
    padding: 10px 15px;
  }

  .panel-body {
    padding: 15px;
  }

  .panel-group .panel+.panel {
      margin-top: 10px;
  }

}


.pagination,
.pagination li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination li { 
  background-color: #1890ff; 
  border: 1px solid #1890ff;
}
.pagination>li>a, .pagination>li>span {
  border: none;
}

.pagination li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.pagination li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination a {
  font-weight: 300;
  padding-top: 1px;
  text-decoration:none;  
  border: 1px solid #1890ff;
  border-left-width: 0;
  min-width:44px;
  min-height:44px;
  color: rgba(255,255,255,.85);  
}

.pagination li:not([class*="current"]) a:hover { 
  background-color: rgba(255,255,255,.2);
  color: #fff;
}

.pagination li.disable {
  background-color: #fafafa !important;
}
.pagination li.disable a {
  color: #666 !important;
  cursor: initial;
}

.pagination li:not([class*="current"]) a:focus,
.pagination li:not([class*="current"]) a:active {
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,.25);
  border-left-width:1px;
}

.pagination li:first-of-type a {
  border-left-width: 1px;
}

/*.pagination li:first-of-type span,
.pagination li:last-of-type span,
.pagination li:nth-of-type(2) span,
.pagination li:nth-last-of-type(2) span { 
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.pagination li:first-child a::before,
.pagination li:last-child a::after,
.pagination li:nth-of-type(2) a::before,
.pagination li:nth-last-of-type(2) a::after {  
  display: inline-block;
  font-family: Fontawesome;  
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.pagination li:first-child a::before,
.pagination li:last-child a::after { content: "\f100"; }

.pagination li:nth-of-type(2) a::before,
.pagination li:nth-last-of-type(2) a::after { content: "\f104"; }*/

.pagination li:last-child a::after,
.pagination li:nth-last-of-type(2) a::after { transform: rotate(180deg); }

.pagination li.current a { 
  padding-top:.25em;
  color: rgba(255,255,255,1);
  background-color: rgba(255,255,255,.15);
  cursor: default;
  pointer-events: none;
}

@media only screen and ( max-width: 64.063em ) {  
  .pagination li:first-child,
  .pagination li:last-child {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li:nth-of-type(2) a { border-left-width: 1px; }

}

@media only screen and ( max-width: 40.063em ) {  
  .pagination li {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li.current,
  .pagination li:first-of-type,
  .pagination li:last-of-type,
  .pagination li:nth-of-type(2),
  .pagination li:nth-last-of-type(2){
    position: initial;
    top: initial;
    left: initial;
  }

  .pagination li:nth-of-type(2) a { border-left-width: 0; }

}

@media only screen and ( max-width: 30.063em ) {  
  
  h1 { font-size: 1.35em !important; }
  
  .pagination li:first-child,
  .pagination li:last-child {
    /* screen readers only */
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .pagination li:nth-of-type(2) a { border-left-width: 1px; }

}

@media only screen and ( max-width: 15.063em ) {  /* For watches? */
  
  .pagination li { width: 50%;}
  
  .pagination li.current { 
    order: 2;
    width: 100%;
    border-left-width: 1px;
      
  }  

}
